<template>
  <ValidationObserver ref="observer">
    <vs-row class="mb-2">
      <vs-row>
        <vs-col vs-sm="12">
          <h3>
            {{ $t('catalog.refreshSettings.header') }}
          </h3>
        </vs-col>
      </vs-row>
      <br>
      <vs-col class="mb-12">
        <vs-col>
          <p>{{ $t('catalog.refreshSettings.description') }}</p>
          <p>{{ $t('catalog.refreshSettings.maxCount').replace('%maxDayCount%', maxDayCount) }}</p>
          <p>{{ $t('catalog.refreshSettings.timeDescription') }}</p>
          <div>
            <b-input-group v-for="(item, index) in getCustomerPeriods" :key="index" class="mb-1">
              <vs-row v-if="index === 0">
                <vs-col vs-w="12">
                  <b-form-timepicker
                    :id="`test_${index}`" v-model="item.date"
                    :hour12="false" hour-cycle="h24" now-button
                    v-bind="getLabels()"
                  />
                </vs-col>
              </vs-row>
              <vs-row v-else>
                <vs-col vs-lg="10" vs-sm="8" vs-xs="6" class="pr-1">
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="`required|distinctTime:${getTimesWithout(item.date)}|refreshTime:${getTimesWithout(item.date)}`"
                    name="refreshTimeInput"
                  >
                    <b-form-timepicker
                      :id="`test_${index}`" v-model="item.date"
                      :hour12="false" hour-cycle="h23" now-button
                      v-bind="getLabels()"
                    />
                    <span id="error">{{ getTranslateError(errors[0]) }}</span>
                  </validation-provider>
                </vs-col>
                <vs-col
                  vs-lg="2" vs-sm="4" vs-xs="6"
                  vs-type="flex" vs-justify="center"
                  vs-align="center"
                >
                  <b-button v-if="index > 0" variant="danger" class="w-100" @click="$delete(getCustomerPeriods, index)">
                    <feather-icon icon="Trash2Icon" size="18" />
                    Remove
                  </b-button>
                </vs-col>
              </vs-row>
            </b-input-group>
          </div>
          <vs-row>
            <vs-col vs-lg="4" vs-sm="6" vs-xs="12">
              <b-button
                v-if="getCustomerPeriods.length < maxDayCount"
                variant="outline-primary"
                title="Danger variant"
                :disabled="disableButton"
                class="w-100"
                @click="addNewDate"
              >{{ $t('catalog.refreshSettings.addNewPeriod') }}
              </b-button>
            </vs-col>
          </vs-row>
        </vs-col>
      </vs-col>
    </vs-row>
  </ValidationObserver>
</template>

<script>
import Vue from 'vue'
import store from '@/store'

export default Vue.extend({
  name: 'CatalogRefreshSettings',
  data: () => ({
    maxDayCount: 4,
  }),

  computed: {
    getCustomerPeriods() {
      return store.getters['catalogRefreshSettings/getCatalogRefreshSettings']
    },

    disableButton() {
      return store.getters['catalogRefreshSettings/getCatalogRefreshSettings'].length >= this.maxDayCount
    },
  },
  async mounted() {
    await store.dispatch('catalogRefreshSettings/getCatalogPeriodicData', this.getCatalogId())
  },
  methods: {
    getTranslateError(error) {
      return error ? this.$t(`catalog.refreshSettings.errors.${error}`) : undefined
    },
    getTimesWithout(timeToOut) {
      let find = false
      const c = store.getters['catalogRefreshSettings/getCatalogRefreshSettings'].map(timeElement => timeElement.date).filter(time => {
        if (!find && timeToOut === time) {
          find = true
          return false
        }
        return true
      })
      return c
    },
    validate() {
      return this.$refs.observer.validate()
    },
    getCatalogId() {
      return this.$route.params.catalogId
    },
    addNewDate() {
      if (store.getters['catalogRefreshSettings/getCatalogRefreshSettings'].length >= this.maxDayCount) {
        return
      }
      const actualHour = new Date().getHours()
      const actualMinutes = (`0${new Date().getMinutes()}`).substr(-2)
      store.dispatch('catalogRefreshSettings/addNewDate', { date: `${actualHour}:${actualMinutes}` })
    },
    getLabels() {
      return {
        labelNowButton: this.$t('catalog.timeSelect.labelNowButton'),
        labelHours: this.$t('catalog.timeSelect.labelHours'),
        labelMinutes: this.$t('catalog.timeSelect.labelMinutes'),
        labelCloseButton: this.$t('catalog.timeSelect.labelCloseButton'),
      }
    },
  },
})
</script>
<style scoped>
#error {
  color: red
}
</style>
