<template>
  <div>
    <b-card>
      <CatalogRefreshSettings ref="refreshSettings" />
      <vs-row class="mt-2">
        <vs-col vs-lg="9" vs-sm="3" vs-xs="0" />
        <vs-col vs-lg="3" vs-sm="9" vs-xs="12">
          <vs-button class="w-100" @click="save()">
            {{ $t('catalog.refreshSettings.save') }}
          </vs-button>
        </vs-col>
      </vs-row>
    </b-card>
  </div>
</template>
<script>
import CatalogRefreshSettings from '@/views/components/catalog/CatalogRefreshSettings.vue'
import store from '@/store'

export default {
  components: { CatalogRefreshSettings },
  methods: {
    save() {
      this.$refs.refreshSettings.validate().then(result => {
        if (!result) {
          return
        }
        store.dispatch('catalogRefreshSettings/storeCatalogPeriodicData', this.$route.params.catalogId)
      })
    },
  },
}
</script>
